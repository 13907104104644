<template>
  <div>
    <!-- 代理品牌 -->
    <div class="bannerbox">
      <!-- <img src="https://qn.icgushi.com/NANJIXIONGDAILI%3ABANNER.png" alt="" /> -->
      <img src="https://qn.icgushi.com/NANJIXIONG/DAILIBBB.png" alt="" />
      
    </div>
    <div class="agentbrandBox">
      <div class="agentTitle">代理品牌</div>

      <div class="centerimg">
        <img
          src="https://qn.icgushi.com/NANJIXIONGSHOUA%3APINPAI.png"
          alt=""
          class="img"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang='scss'>
.bannerbox {
  width: 100%;
  height: 480px;
  img {
    width: 100%;
    height: 480px;
  }
}
.agentbrandBox {
  width: 1320px;
  height: 856px;
  margin: 0 auto;
  .agentTitle {
    width: 1320px;
    // height: 100px;
    text-align: center;
    // line-height: 100px;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 110px;
    margin-bottom: 121px;
  }
  .centerimg {
    width: 1320px;
    height: 467px;
    margin: 0 auto;
    .img {
      width: 1320px;
      height: 467px;
    }
  }
}
</style>
